import DDSidebarListItems from "../molecules/DDSidebarListItems";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Icon from "atoms/Icon";

const DoctorSidebar = ({ className, isSidebarOpen, toggleSidebar }) => {
  const router = useRouter();

  const sideBarDetails = [
    {
      menuActive: router.pathname.startsWith("/doctor-panel/patients"),
      menuItem: "Patients",
      menuIconNormalSrc: "/images/icons/icon-profile-b.svg",
      menuIconActiveSrc: "/images/icons/icon-profile-g.svg",
      menuIconAlt: "Patients",
      onClick: () => changeRoute("/doctor-panel/patients"),
    },
    {
      menuActive: router.pathname.startsWith("/doctor-panel/profile"),
      menuItem: "Profile",
      menuIconNormalSrc: "/images/icons/icon-profile-b.svg",
      menuIconActiveSrc: "/images/icons/icon-profile-g.svg",
      menuIconAlt: "Profile",
      onClick: () => changeRoute("/doctor-panel/profile"),
    },
  ];

  const changeRoute = (route) => {
    const initialSidebarState = window.matchMedia('(max-width: 768px)').matches
    if (initialSidebarState) {
      toggleSidebar()
    }
    router.push(route)
  }
  return (
    <>
      <div
        className={`fixed top-0  lg:left-0 h-full bg-grey2 z-40 pt-7 pl-5 lg:pl-10 pr-4 pb-4 border-r border-grey1 transition-all shadow-ddSidebar lg:shadow-none ${isSidebarOpen
          ? "lg:w-[224px] -left-full"
          : "w-[85%] lg:w-[126px] left-0"
          } ${className}}`}
      >
        <div className="hidden lg:block">
          <Icon
            className="cursor-pointer"
            iconSrc={`${isSidebarOpen
              ? "/images/icons/hamburger.svg"
              : "/images/icons/hamburger.svg"
              }`}
            iconWidth="32"
            iconHeight="32"
            onClick={toggleSidebar}
          />
        </div>
        <div className="block lg:hidden pt-16">
          <Icon
            className="cursor-pointer"
            iconSrc="/images/icons/close.svg"
            iconWidth="32"
            iconHeight="32"
            onClick={toggleSidebar}
          />
        </div>

        <ul className="mt-9">
          {sideBarDetails.map((sidebar, idx) => (
            <DDSidebarListItems
              key={idx}
              menuActive={sidebar.menuActive}
              menuItem={sidebar.menuItem}
              menuIconNormalSrc={sidebar.menuIconNormalSrc}
              menuIconActiveSrc={sidebar.menuIconActiveSrc}
              menuIconAlt={sidebar.menuIconAlt}
              onClick={sidebar.onClick}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </ul>
      </div>
      {!isSidebarOpen && (
        <div className="fixed left-0 top-0 bg-white w-full h-screen opacity-60 z-[31] lg:hidden"></div>
      )}
    </>
  );
};

DoctorSidebar.defaultProps = {
  className: "",
  isSidebarOpen: true,
  toggleSidebar: () => { },
};

DoctorSidebar.propTypes = {
  className: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default DoctorSidebar;
