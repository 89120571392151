import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";

const DDSidebarListItems = ({
  menuItem,
  menuIconNormalSrc,
  menuIconActiveSrc,
  menuIconAlt,
  menuActive,
  isIconAvail,
  onClick,
  isSidebarOpen,
}) => {
  return (
    <li
      className={`flex items-center py-2.5 px-4 mb-3 cursor-pointer`}
      onClick={onClick}
    >
      {isIconAvail && (
        <div className="flex items-center shrink-0">
          {!menuActive ? (
            <Image
              src={menuIconNormalSrc}
              width={24}
              height={24}
              alt={menuIconAlt}
            />
          ) : (
            <Image
              src={menuIconActiveSrc}
              width={24}
              height={24}
              alt={menuIconAlt}
            />
          )}
        </div>
      )}
      <div
        className={`text-base ml-4 ${
          menuActive ? "text-darkGreen" : "text-black"
        } ${isSidebarOpen ? "block" : "block lg:hidden"}`}
      >
        {menuItem}
      </div>
    </li>
  );
};

DDSidebarListItems.defaultProps = {
  menuItem: "",
  menuIconNormalSrc: "",
  menuIconActiveSrc: "",
  menuIconAlt: "",
  menuActive: false,
  isIconAvail: true,
  onClick: () => {},
  isSidebarOpen: true,
};

DDSidebarListItems.propTypes = {
  menuItem: PropTypes.string,
  menuIconNormalSrc: PropTypes.string,
  menuIconActiveSrc: PropTypes.string,
  menuIconAlt: PropTypes.string,
  menuActive: PropTypes.bool,
  isIconAvail: PropTypes.bool,
  onClick: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default DDSidebarListItems;
