import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DDSidebar from "organisms/DDSidebar";
import { Providers } from "utils/Provider";
import { useDrSidebarContext } from "components/context/doctorSideBarContext";


const DDLayout = ({ children }) => {
  const { isSidebarOpen, toggleSidebar } = useDrSidebarContext()


  return (
    <div>
      <DDSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        className={` min-h-screen bg-grey3 overflow-y-auto lg:pt-8 pb-6 transition-all ${isSidebarOpen ? "px-4 lg:pl-[240px]" : "px-4 lg:pl-[142px]"
          }`}
        isSidebarOpen={isSidebarOpen}
      >
        <div className="max-w-[968px] w-full mx-auto">
          <Providers>{children}</Providers>
        </div>
      </div>
    </div>
  );
};
DDLayout.propTypes = {
  children: PropTypes.node,
};
export default DDLayout;
