import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Image from "next/image";

const buttonStyles = {
  primary:
    "bg-secondary-900 hover:bg-secondary-400 focus:bg-secondary-400 active:bg-secondary-400 visited:bg-secondary-400 text-white rounded-full disabled:bg-secondary-100 disabled:text-secondary-300",
  ghost:
    "text-secondary-900 outline outline-1 outline-secondary-900 hover:bg-primary-50 hover:outline-secondary-400 hover:text-secondary-400 focus:bg-transparent focus:outline-2 focus:outline-secondary-400 focus:text-secondary-900 active:bg-transparent active:outline-secondary-400 active:text-secondary-900 rounded-full disabled:bg-secondary-100 disabled:text-secondary-300",
  borderLess:
    "text-secondary-900 hover:bg-secondary-100 hover:text-secondary-400 focus:text-secondary-400 focus:bg-secondary-100 active:bg-secondary-100 rounded-full disabled:bg-secondary-100 disabled:text-secondary-300",
  secondary:
    "bg-success-100 text-white hover:bg-success-100 focus:bg-success-100 active:bg-success-100 rounded-2xl",
  login:
    "bg-green1 text-black hover:bg-green1 focus:bg-green1 active:bg-green1 rounded-full disabled:bg-green1",
  normal: "text-neutral-900 ",
  link: "underline text-blue",
};

const sizes = {
  extraSmall: "py-2 px-6 text-sm",
  small: "py-3 px-6 text-sm",
  medium: "py-3.5 px-8 text-base",
  large: "py-4 px-8 text-xl",
  extraLarge: "p-4 md:p-5 text-base md:text-[18px]/[24px]",
};

const iconPositions = {
  left: "flex-row-reverse space-x-reverse",
  right: "flex-row",
};

const iconSize = {
  extraSmall: "p-2",
  small: "p-2",
  medium: "p-3",
  large: "p-4",
};

const Button = ({
  icon,
  iconPosition,
  label,
  onClick,
  type,
  style,
  fullWidth,
  className,
  disabled,
  size,
  fontWeight,
  btnClass,
}) => {
  const defaultStyle = buttonStyles[style] || buttonStyles.primary;

  const buttonClasses = classnames(
    " disabled:outline-none transform transition-transform active:scale-90 duration-300 flex items-center justify-center space-x-2",
    defaultStyle,
    fullWidth && "w-full justify-center",
    className,
    fontWeight,
    btnClass,
    !icon || label ? sizes[size] : iconSize[size],
    {
      [iconPositions[iconPosition]]: iconPosition,
    }
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${buttonClasses}`}
      disabled={disabled}
    >
      {icon && (
        <Image
          src={icon}
          width={size === "extraSmall" ? 16 : 24}
          height={size === "extraSmall" ? 16 : 24}
          alt="icon"
        />
      )}
      {label && (
        <span className={classnames({ [className]: icon })}>{label}</span>
      )}
    </button>
  );
};

Button.propTypes = {
  style: PropTypes.oneOf(Object.keys(buttonStyles)),
  size: PropTypes.oneOf(Object.keys(sizes)),
  iconPosition: PropTypes.oneOf(Object.keys(iconPositions)),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
  btnClass: PropTypes.string,
};

Button.defaultProps = {
  icon: null,
  iconPosition: iconPositions.left,
  label: "",
  onClick: () => {},
  type: "button",
  style: "primary",
  fullWidth: false,
  className: "",
  disabled: false,
  size: "medium",
  fontWeight: "font-semibold",
  btnClass: "",
};

export default Button;
