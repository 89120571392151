import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { callToCustomer, getAllAdminPatientUsers, updatePatient } from "./hc.services";
import { useRouter } from 'next/router';
import { getCrmPatients } from "./nutritionist.service";


export const useAdminPatients = (params, setShowModal) => {
    return useQuery(['patients', params], () => getAllAdminPatientUsers(params));
}

export function useAssignHealthCoach() {
    const router = useRouter();
    const queryClient = useQueryClient();
    const updataePatientMutation = useMutation({
        mutationFn: ({ patientId, body }) => updatePatient({ patientId, body }),
        onSuccess: (res) => {
            queryClient.invalidateQueries(['patients'])
            // useAdminPatients({
            //     offset: 0,
            //     limit: 10,
            // }, false)
        }
    })

    return { updataePatientMutation }
}

export function useClickToCallAirtel() {
    const queryClient = useQueryClient();
    const clickToCall = useMutation({
        mutationFn: ({ body }) => callToCustomer(body),
        onSuccess: (res) => {
            queryClient.invalidateQueries(['patients'])
        }
    })

    return { clickToCall }
}

export const useCrmPatients = (params) => {
    return useQuery(['crm-assigned-patients', params], () => getCrmPatients(params));
}